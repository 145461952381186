// icons
import { Button, Container, Grid, Stack, Typography } from '@mui/material';
// @mui
import { alpha, styled } from '@mui/material/styles';
import { imageProps } from '@utils/image';
import NextLink from 'next/link';
import Routes from 'src/routes';
// utils
import cssStyles from '../../utils/cssStyles';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  padding: theme.spacing(14, 0),
  ...cssStyles(theme).bgImage({
    url: imageProps("image-4210ac8f6dda085e822fca683bb776b4c599314f-3840x2560-jpg")?.src || "",
    startColor: `${alpha(theme.palette.grey[900], 0.88)}`,
    endColor: `${alpha(theme.palette.grey[900], 0.88)}`,
  }),
  [theme.breakpoints.up('md')]: {
    ...cssStyles(theme).bgImage({
      direction: 'right',
      url: imageProps("image-4210ac8f6dda085e822fca683bb776b4c599314f-3840x2560-jpg")?.src || "",
      startColor: `${alpha(theme.palette.grey[900], 0)} 0%`,
      endColor: `${alpha(theme.palette.grey[900], 1)} 50%`,
    }),
    backgroundPosition: 'center, left ',
    backgroundSize: 'cover, 50%',
  },
}));

// ----------------------------------------------------------------------

export default function Newsletter() {
  return (
    <RootStyle>
      <Container>
        <Grid container spacing={3} justifyContent={{ md: 'flex-end', xs: 'center' }}>
          <Grid item xs={12} md={5}>
            <Stack
              spacing={3}
              sx={{
                color: 'common.white',
                textAlign: { xs: 'center', md: 'left' },
                alignItems: { xs: 'center', md: 'start' },
              }}
            >
              <Typography variant="h2">Kontakt oss</Typography>
              <Typography>
                Vi er den punktlige elektrikeren som utfører oppdrag for både private og bedrifter i
                hele Bergensområdet.
              </Typography>

              <Stack direction="row" spacing={3}>
                <NextLink href={Routes.form} passHref>
                  <Button variant="contained" size="large" sx={{ minWidth: 48 }}>
                    Bestill befaring
                  </Button>
                </NextLink>
                <NextLink href={Routes.contact} passHref>
                  <Button variant="outlined" color="inherit" size="large" sx={{ minWidth: 48 }}>
                    Kontaktside
                  </Button>
                </NextLink>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </RootStyle>
  );
}
